import { ISurveyData } from '../Types';

const DEFAULT_USER_DATA: ISurveyData = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  allowSMS: true,
  email: '',
  currentAddress: '',
  inRegion: false,
  rememberMe: false,

  isFirstTime: false,
  haveAppointment: false,

  alcoholQ: undefined,
  opioidQ: undefined,
  stimulantsQ: undefined,
  otherSubstancesQ: undefined,
  mentalHealthQ: undefined,

  homeAddress: undefined,
  familyDoctor: undefined,
  pregnantQ: 'No/Does not apply',

  healthCardNumber: undefined,
  healthCardBlob: undefined,

  healthIdNumber: undefined,
  registrationNumber: undefined,
  dobDay: undefined,
  dobMonth: undefined,
  dobYear: undefined,

  userAgent: undefined,
  screenWidth: undefined,
  screenHeight: undefined,
  screenOrientation: undefined,

  hasNoHealthCard: false,
  missingHealthCardReason: '',
};

export default DEFAULT_USER_DATA;
